import type { Ref } from 'vue'
import { v4 as uuidv4 } from 'uuid'
import type { ExtraIssuesListFilters, IssueTypeState, IssuesListFilters, IssuesListPagination } from './types'
import { IssueSortTypes } from './types'
import type { ApiResponseData } from '~/api/types'
import { SortDirection } from '~/api/types'
import type { ShortIssueWithId } from '~/api/modules/issues/services/getIssuesList/getIssuesList.types'
import { api } from '~/api/Api'
import type { IssueWithLoadingState } from '~/stores/issues/types'
import type { MaybeDeepReadonly } from '~/types/helpers'

export type BaseGetIssuesOptions = {
  filters: Partial<MaybeDeepReadonly<IssuesListFilters>>
  pagination: IssuesListPagination
  search: string
  sort?: {
    key: IssueSortTypes
    direction: SortDirection
  }
}

export function getIssues(options: BaseGetIssuesOptions & {
  extraParams?: Partial<ExtraIssuesListFilters>
}): Promise<ApiResponseData<ShortIssueWithId[]>> {
  return api.issuesModule.getIssuesList({
    search: options.search,
    filters: {
      ...(options.filters || {}),
      ...(options.extraParams || {}),
    },
    pagination: options.pagination,
    ...(options.sort ? { sort: options.sort } : {}),
  })
}

export function fetchAllIssues(options: BaseGetIssuesOptions) {
  return getIssues({
    ...options,
    extraParams: {
      isNotArchived: true,
    },
  })
}

export function fetchDefaultIssues({ extraParams, ...options }: BaseGetIssuesOptions & { extraParams?: Pick<ExtraIssuesListFilters, 'excludedId'> }) {
  return getIssues({
    ...options,
    extraParams: { ...extraParams || {}, isNotArchived: true },
  })
}

export function fetchOnlyOurIssues(options: BaseGetIssuesOptions) {
  return getIssues({
    ...options,
    extraParams: { onlyOur: true, isNotArchived: true },
  })
}

export function fetchArchivedIssues({ extraParams, ...options }: BaseGetIssuesOptions & { extraParams?: Pick<ExtraIssuesListFilters, 'excludedId'> }) {
  return getIssues({
    ...options,
    extraParams: { ...extraParams || {}, isArchived: true },
  })
}

export function mapIssues(issues: ShortIssueWithId[], loading?: boolean): IssueWithLoadingState[] {
  return issues.map(issue => ({
    id: issue.id,
    issue,
    loading: Boolean(loading),
  }))
}

export async function fetchMoreIssues(
  issues: Ref<IssueTypeState>,
  loader: () => Promise<ApiResponseData<ShortIssueWithId[]>>,
) {
  const { list, total, pagination } = issues.value

  const lastIndex = list.length

  const leftIssuesToLoad = total - lastIndex
  const count = leftIssuesToLoad > pagination.limit ? pagination.limit : leftIssuesToLoad

  // Make the placeholder array of issue with loading state
  for (let i = 0; i < count; i++) {
    issues.value.list.push({ loading: true, id: uuidv4() })
  }

  const { data, meta } = await loader()

  if (meta) {
    issues.value.total = meta.pagination?.total || 0
  }

  data?.forEach?.((issue, index) => {
    const currentIssue = list[lastIndex + index]

    if (currentIssue) {
      currentIssue.loading = false
      currentIssue.issue = issue
    }
  })
}

export function buildRequestSorting(sort: IssueSortTypes) {
  return sort !== IssueSortTypes.DEFAULT
    ? {
        key: sort,
        direction: SortDirection.DESC,
      }
    : null
}
