import type { IssueWithLoadingState } from '~/stores/issues/types'

import type { IssueTypeState } from '~/composables/issues/useIssuesList/types'

export const ONLY_OUR_ISSUES_LIMIT = 4
export const INITIAL_PAGINATION = {
  start: 0,
  limit: 10,
  offset: 0,
}
export const ISSUES_STATE_DEFAULT: IssueTypeState = {
  total: 0,
  pagination: { ...INITIAL_PAGINATION },
  isLoading: false,
  list: [] as IssueWithLoadingState[],
}
