import { cloneDeep } from 'lodash-es'
import type { ComputedRef, Ref } from 'vue'
import type { IssuesListFilters, IssuesListPagination, IssuesTypesState } from './types'
import { IssueSortTypes, IssuesTypes } from './types'
import type { BaseGetIssuesOptions } from '~/composables/issues/useIssuesList/helpers'
import {
  buildRequestSorting,
  fetchAllIssues,
  fetchArchivedIssues,
  fetchDefaultIssues,
  fetchMoreIssues,
  fetchOnlyOurIssues,
  mapIssues,
} from '~/composables/issues/useIssuesList/helpers'
import type { ApiResponseData } from '~/api/types'
import type { ShortIssueWithId } from '~/api/modules/issues/services/getIssuesList/getIssuesList.types'
import {
  INITIAL_PAGINATION,
  ISSUES_STATE_DEFAULT,
  ONLY_OUR_ISSUES_LIMIT,
} from '~/composables/issues/useIssuesList/constants'

export const loaders = {
  [IssuesTypes.allIssues]: fetchAllIssues,
  [IssuesTypes.issues]: fetchDefaultIssues,
  [IssuesTypes.onlyOurIssues]: fetchOnlyOurIssues,
  [IssuesTypes.archivedIssues]: fetchArchivedIssues,
  [IssuesTypes.issuesForCompare]: fetchAllIssues,
}

export function useIssuesLists(filter: ComputedRef<Partial<IssuesListFilters>>, sort: Ref<IssueSortTypes>, search: ComputedRef<string>) {
  // const issues = getDefaultIssues();
  const issues = getDefaultIssues()
  const comparedIssuesSearch = useState(IssuesTypes.issuesForCompare)
  const loadingSearch = ref(false)
  const isListFetchNeeded = (type: IssuesTypes, ignoreCache?: boolean) => ignoreCache || !issues[type].value.list.length

  const baseRequestConfiguration = (type: IssuesTypes) => {
    const requestSorting = buildRequestSorting(sort.value)

    return {
      search: search.value,
      filters: filter.value,
      pagination: issues[type].value.pagination,
      ...(requestSorting ? { sort: requestSorting } : {}),
    }
  }

  async function fetchInitialFiltersList(
    /**
     * Ignore cache can be used, for example, after pagination changed
     */
    ignoreCache?: boolean,
  ) {
    const isDefaultSorting = sort.value === IssueSortTypes.DEFAULT
    const defaultSortLoad = isDefaultSorting && isListFetchNeeded(IssuesTypes.onlyOurIssues, ignoreCache)

    async function fetchDefaultSortedIssues() {
      issues[IssuesTypes.issues].value.pagination = { ...INITIAL_PAGINATION }

      await saveIssues(
        IssuesTypes.onlyOurIssues,
        () => loaders[IssuesTypes.onlyOurIssues](baseRequestConfiguration(IssuesTypes.onlyOurIssues)),
      )

      // Load default issues list
      await saveIssues(
        IssuesTypes.issues,
        () => loaders[IssuesTypes.issues]({
          ...baseRequestConfiguration(IssuesTypes.issues),
          extraParams: { excludedId: issues[IssuesTypes.onlyOurIssues].value.list.map(({ id }) => Number(id)) },
        }),
      )
    }

    issues[IssuesTypes.allIssues].value.pagination = { ...INITIAL_PAGINATION }
    issues[IssuesTypes.archivedIssues].value.pagination = { ...INITIAL_PAGINATION }

    await Promise.all([
      // DEFAULT SORT ONLY
      ...defaultSortLoad
        ? [fetchDefaultSortedIssues()]
        : [],

      // OTHER SORT TYPES
      ...(!isDefaultSorting && isListFetchNeeded(IssuesTypes.allIssues, ignoreCache))
        ? [
            saveIssues(
              IssuesTypes.allIssues,
              () => loaders[IssuesTypes.allIssues](baseRequestConfiguration(IssuesTypes.allIssues)),
            ),
          ]
        : [],

      // LOAD ARCHIVED ISSUES IF SEARCH IS NOT EMPTY
      ...(search.value.trim().length && isListFetchNeeded(IssuesTypes.archivedIssues, ignoreCache))
        ? [
            saveIssues(IssuesTypes.archivedIssues, () => loaders[IssuesTypes.archivedIssues](baseRequestConfiguration(IssuesTypes.archivedIssues))),
          ]
        : [],
    ])

    return issues
  }

  async function loadMoreIssues(type: IssuesTypes) {
    const issuesType = issues[type]

    setPagination(type, {
      ...issuesType.value.pagination,
      offset: issuesType.value.pagination.offset + issuesType.value.pagination.limit,
    })

    await fetchMoreIssues(issuesType, () => loaders[type]({
      ...baseRequestConfiguration(type),
      extraParams: {
        ...(type === IssuesTypes.issues
          ? { excludedId: issues[IssuesTypes.onlyOurIssues].value.list.map(({ id }) => Number(id)) }
          : {}),
      },
    }))
  }

  async function fetchIssuesBySearch(search: string) {
    if (!search) {
      comparedIssuesSearch.value = []
      return
    }

    loadingSearch.value = true
    const { data } = await fetchAllIssues({
      search,
      pagination: {
        start: 0,
        limit: 10,
      },
    } as BaseGetIssuesOptions)
    loadingSearch.value = false
    comparedIssuesSearch.value = data
  }

  async function saveIssues(key: IssuesTypes, request: () => Promise<ApiResponseData<ShortIssueWithId[]>>) {
    issues[key].value.isLoading = true

    const { data, meta } = await request()

    issues[key].value.total = meta?.pagination?.total || 0
    issues[key].value.list = mapIssues(data, false)
    issues[key].value.isLoading = false

    return issues[key].value
  }

  function setPagination(type: IssuesTypes, pagination: IssuesListPagination) {
    issues[type].value.pagination = pagination
  }

  return {
    issues,
    fetchInitialFiltersList,
    fetchMoreIssues: loadMoreIssues,
    setPagination,
    fetchIssuesBySearch,
    comparedIssuesSearch,
    loadingSearch,
  }
}

function getDefaultIssues(): IssuesTypesState {
  return {
    [IssuesTypes.onlyOurIssues]: useState(IssuesTypes.onlyOurIssues, () => cloneDeep({
      ...ISSUES_STATE_DEFAULT,
      pagination: {
        ...ISSUES_STATE_DEFAULT.pagination,
        limit: ONLY_OUR_ISSUES_LIMIT,
      },
    })),
    [IssuesTypes.issues]: useState(IssuesTypes.issues, () => cloneDeep(ISSUES_STATE_DEFAULT)),
    [IssuesTypes.allIssues]: useState(IssuesTypes.allIssues, () => cloneDeep(ISSUES_STATE_DEFAULT)),
    [IssuesTypes.archivedIssues]: useState(IssuesTypes.archivedIssues, () => cloneDeep(ISSUES_STATE_DEFAULT)),
  }
}
